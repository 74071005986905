@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;800;900&family=Sora:wght@500;600&display=swap');

.NFT_BG {
    height: 100vh;
    width: 100%;
    background-image: url("../../new logo/Rectangle\ 71.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.NFT_BG_CLOUDS {
    position: absolute;
    width: 22%;
    z-index: 1;
}

.CLOUD1 {
    top: 290px;
    right: 0px;
}

.CLOUD2 {
    top: 350px;
    left: 0px;
}

.NFT_CARDS {
    height: 75vh;
    width: 55%;
    z-index: 2;
    position: relative;
}

.NFT_CARDS>img {
    position: absolute;
    inset: 0;
    z-index: 0;
}

.NFT_CARD_CONTENT {
    position: absolute;
    z-index: 3;
    width: 100%;
    top: 10px;
    text-align: center;
}

.NFT_CARD_CONTENT_CROSS_BTN {
    position: absolute;
    width: 30px;
    top: 5px;
    right: 15px;
    cursor: pointer;
}

.NFT_CARD_HEADING {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
}

.CARDS_DIV {
    width: 100%;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Single_CARD {
    width: 260px;
    height: 230px;
    border-top-right-radius: 5px;
    box-shadow: 6px 6px 0px #62C8A9;
}

.Single_CARD_IMAGE {
    width: 100%;
    height: 100%;
    margin-bottom: -4px;
}

.Single_CARD_CONTENT {
    background-color: white;
    border: 1px solid black;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    width: 99.40%;
    box-shadow: 6px 6px 0px #62C8A9;
}

.Single_CARD_CONTENT_PARA {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.Single_CARD_CONTENT_BTN_DIV {
    width: 50%;
    display: flex;
    padding-left: 5px;
    align-items: center;
}

.Single_CARD_CONTENT_BTN {
    background-color: #FE88E4;
    padding: 3px 10px;
    border-radius: 5px;
    height: 26px;
    border: 1px solid black;
    width: 80%;
    font-family: 'Montserrat', sans-serif;

}

.nft-message {
    width: 100%;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nft-message span {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: black;
}


@media only screen and (max-width: 768px) {

    .CLOUD1 {
        top: 0px;
        right: 0px;
        width: 25%;
    }

    .CLOUD2 {
        bottom: 0;
        left: 0px;
        width: 30%;
    }

    .NFT_CARDS {
        width: 70%;
        height: 60vh;
        top: 0;
        display: block;
    }

    .NFT_CARD_CONTENT {
        top: 70px;
    }

    .CARDS_DIV {
        width: 100%;
        margin: 50px auto;
        height: 24vh;
    }

    .Single_CARD {
        width: 240px;
        height: 210px;
    }


    .Single_CARD_CONTENT_PARA p {
        font-size: 12px;
    }

    .Single_CARD_CONTENT_BTN {
        font-size: 12px;
        font-weight: bold;
        width: 86%;
    }

}

@media only screen and (max-width: 480px) {

    .CLOUD1 {
        top: 0px;
        right: 0px;
        width: 30%;
    }

    .CLOUD2 {
        width: 35%;
        bottom: 20px;
        left: 0;
        top: auto;
    }

    .NFT_CARDS {
        width: 80%;
        height: 400px;
        top: 0;
        display: block;
    }

    .NFT_CARD_CONTENT {
        top: 10px;
    }

    .CARDS_DIV {
        margin: 0 auto;
        width: 85%;
        height: 200px;
    }

    .Single_CARD_CONTENT_PARA p {
        font-size: 12px;
    }

    .Single_CARD {
        width: 90%;
    }

    .nft-message {
        height: 30vh;
    }

}